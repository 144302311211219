.tvbf__header {
    display: flex;
    flex-direction: row;
}

.tvbf__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.tvbf__header-content h1 {
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
}
.tvbf__header-content p {
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 20px;
    line-height: 27px;
    
    color: darkgray;
    margin-top: 1.5rem;
}

.tvbf__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
}

.tvbf__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;

    background: #533A7B;
    font-family: Manrope;
    font-size: 20px;
    line-height: 27px;

    color: darkgray;
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.tvbf__header-content__input button {

    flex: 0.6;
    width: 100%;
    min-height: 50px;

    background-color: #460c67;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: Manrope;
    font-weight: bold;
    font-size: 27px;
    line-height: 27px;
    color: #ffffff;
    cursor: pointer;
    outline: none;
    border: none;
    padding: 0 1rem;
}

.tvbf__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tvbf__header-image img {
    width: 100%;
    height: 100%;
    border-radius: 300px;
}

@media screen and (max-width: 1050px) {
    .tvbf__header {
        flex-direction: column;
    }
    .tvbf__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .tvbf__header h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .tvbf__header p {
        font-size: 16px;
        line-height: 24px;
    }

    .tvbf__header-content__input input,
    .tvbf__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .tvbf__header h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .tvbf__header p {
        font-size: 14px;
        line-height: 24px;
    }

    .tvbf__header-content__input input,
    .tvbf__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }


}