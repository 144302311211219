.tvbf__possibility {
    background: var(--gradient-bar);
    justify-content: center;

}

.tvbf__possibility-heading {
    display: flex;
    justify-content: center;

}

.tvbf__possibility-feature {
    display: flex;
    flex-direction: row;
}