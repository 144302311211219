.tvbf__pastor-bio {
    display: flex;
    flex-direction: row;

}


.tvbf__features-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 4rem 0 2rem;
}

.tvbf__features-text {
    font-family: Manrope;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
}

.tvbf__pastor {
    
}

.tvbf__pastor img{
    border-radius: 10px;
    box-shadow: 5px 10px 8px;
}